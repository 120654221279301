import { FC, useState } from "react";
import { FormControlWrapper } from "./textarea.styled";

interface ITextareaProps {
  id: string;
  value: string;
  onChange: (e: any) => void;
  label: string;
  name?: string;
  fullWidth?: boolean;
}

const Textarea: FC<ITextareaProps> = (props) => {
  const { fullWidth, id, label, name, onChange, value } = props;
  const [isDirty, setIsDirty] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const handleFocus = () => {
    setIsFocus(true)
    setIsDirty(true)
  }
  const handleBlur = () => {
    setIsFocus(false)
  }

  return (
    <FormControlWrapper>
      <textarea className={fullWidth? 'w100': ''} id={id} name={name} value={value} onFocus={handleFocus} onBlur={handleBlur} onChange={onChange} />
    </FormControlWrapper>
  );
};

export default Textarea;
