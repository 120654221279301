import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { calculateAmount } from "../../utils";

export type PageState = {
  items: Array<any>;
};

const initialState: PageState = {
  items: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addToCart: (state, action: PayloadAction<any>) => {
      let items = JSON.parse(JSON.stringify(state.items));
      const item = items.find((i: any) => i.id === action.payload.id);
      if (!item) {
        items.push({ ...action.payload, subTotal: calculateAmount(action.payload.quantity * action.payload.price) })
      } else {
        item.quantity += action.payload.quantity;
        if (item.quantity <= 0) {
          items = items.filter((item: any) => item.id !== action.payload.id)
        }
        item.subTotal = calculateAmount(item.quantity * item.price)
      }
      state.items = items
    },
    removeFromCart: (state, action: PayloadAction<any>) => {
      state.items = state.items.filter((item) => item.id !== action.payload.id)
    },
    resetCartSlice: (state) => (initialState),
  },
  extraReducers: (builder) => {

  },
});

export const { addToCart, removeFromCart, resetCartSlice } = cartSlice.actions;

export default cartSlice.reducer;
