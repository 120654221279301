import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/lib/redux/hooks";
import { updateUserType, USER_TYPE } from "src/lib/redux/slices/mainSlice";
import Button, { ButtonGroup } from "src/components/basic/button/button";
import TextField from "src/components/basic/textField/textField";
import Modal from "src/components/modal/modal";

interface ILoginProps {
  open: boolean;
  onClose: () => void;
}

const initialFormValue = {email: '', password: ''};

const LoginModal: FC<ILoginProps> = (props) => {
  const { onClose, open } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState(initialFormValue);

  const handleFormChange = (e:any) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value
    })
  }

  const handleLogin = () => {
    const email = formValue.email;
    setFormValue(initialFormValue)
    switch (email.trim().toLowerCase()) {
      case 'merchant':
        dispatch(updateUserType(USER_TYPE.MERCHANT))
        navigate('/merchant/dashboard')
        break;
      // case 'admin':
        
      //   break;
    
      default:
        dispatch(updateUserType(USER_TYPE.CUSTOMER))
        break;
    }
    onClose()
  }

  const handleClose = () => {
    setFormValue(initialFormValue)
    onClose()
  }

  return (
    <Modal header="Login" open={open} onClose={onClose}>
      <form onSubmit={handleLogin}>
        <TextField id="email" label="Email" fullWidth value={formValue.email} name="email" onChange={handleFormChange} />
        <TextField id="password" label="Password" fullWidth value={formValue.password} name="password" onChange={handleFormChange} />
        <Button type="link" label="Forgot your password?" onClick={()=>{}} />
        <ButtonGroup>
          <Button label="Login" submit />
          <Button type="secondary" label="Cancel" onClick={handleClose} />
        </ButtonGroup>
      </form>
    </Modal>
  );
};

export default LoginModal;
