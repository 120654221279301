import { updateOrderCount, updateOrderList } from "../redux/slices/orderSlice";
import { fetchOrderList, IOrderResponse } from "../service/orderService";

export const getOrders =
  (pn: number, limit: number) =>
  async ({ dispatch }: any) => {
    let orderData: IOrderResponse = await fetchOrderList(pn, limit);

    if(orderData.orderCount){
      dispatch(updateOrderCount(orderData.orderCount))
  }

    if (orderData.orderList) {
      dispatch(updateOrderList(orderData.orderList))
    }
  };
