import { FC, useState } from "react";
import Cart from "src/components/modal/cart/cart";
import LoginModal from "src/components/modal/login/login";
import { Link, NavLink } from "react-router-dom";
import Button from "src/components/basic/button/button";
import Icon from "src/icons/Icon";
import { NavbarWrapper, NavItemWrapper } from "./navbar.styled";
import TextField from "src/components/basic/textField/textField";
import useMediaQuery from "src/lib/hooks/useMediaQuery";
import Modal from "src/components/modal/modal";
import { useAppDispatch, useAppSelector } from "src/lib/redux/hooks";
import { updateSearchString } from "src/lib/redux/slices/productSlice";
import { getProducts } from "src/lib/action/productAction";

interface INavbarProps {}

const Navbar: FC<INavbarProps> = (props) => {
  const dispatch = useAppDispatch();

  const theme = useAppSelector((state) => state?.main?.theme);

  const [showLogin, setShowLogin] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [searchText, setSearchText] = useState("");

  const isSmallScreen = useMediaQuery("(max-width: 750px)");

  const handleSearch = () => {
    dispatch(updateSearchString(searchText));
    dispatch(getProducts({searchString:searchText, pn:1, limit:12}));
  };

  const handleLogin = () => {
    setShowLogin(true);
    setShowNavbar(false);
  };
  const handleCart = () => {
    setShowCart(true);
    setShowNavbar(false);
  };
  const hideNavbar = () => setShowNavbar(false);

  const links = (
    <NavItemWrapper isSmallScreen={isSmallScreen}>
      {/* <li>
        <NavLink
        onClick={hideNavbar}
          to="/"
          className={({ isActive }) =>
            isActive ? "activeClassName" : undefined
          }
        >
          Home
        </NavLink>
      </li> */}
      <li>
        <Link onClick={hideNavbar} to="/product">
          Product
        </Link>
      </li>
      <li>
        <Button label="Login" onClick={handleLogin} />
      </li>
      <li>
        <Button type="icon" label={<Icon name="cart" />} onClick={handleCart} ariaLabel="Show Cart"/>
      </li>
    </NavItemWrapper>
  );

  return (
    <>
      <NavbarWrapper theme={theme}>
        <Link to="/" title="Logo">
          <Icon name="logo" />
        </Link>
        <div>
          <TextField
            icon="search"
            id="searchText"
            label="Search for Products"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onClickIcon={handleSearch}
            onClickIconLabel="Search"
          />
        </div>
        {isSmallScreen ? (
          <Button
            type="icon"
            label={<Icon name="horizontalBars" />}
            onClick={() => setShowNavbar(!showNavbar)}
            ariaLabel="Links"
          />
        ) : (
          <div>{links}</div>
        )}
      </NavbarWrapper>
      <Cart open={showCart} onClose={() => setShowCart(false)} />
      <LoginModal open={showLogin} onClose={() => setShowLogin(false)} />
      <Modal
        position="top"
        header={null}
        open={showNavbar}
        onClose={() => setShowNavbar(false)}
      >
        {links}
      </Modal>
    </>
  );
};

export default Navbar;
