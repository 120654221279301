import { FC } from "react";
import { IconProps } from "./interface";

const Products: FC<IconProps> = (props) => {
  const { fillColor, size, title } = props;
  return (
    <svg
      viewBox="0 0 256 256"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
    >
      {title && <title>{title}</title>}

      <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
        <path
          fill={fillColor}
          d="M 45 37.605 c -0.254 0 -0.509 -0.048 -0.749 -0.146 l -16.076 -6.494 c -1.024 -0.414 -1.519 -1.58 -1.105 -2.604 c 0.414 -1.024 1.58 -1.519 2.604 -1.105 L 45 33.448 l 36.253 -14.646 L 45 4.157 L 8.747 18.803 l 11.485 4.64 c 1.024 0.414 1.519 1.58 1.105 2.604 c -0.414 1.025 -1.58 1.52 -2.604 1.105 L 2.659 20.657 c -0.756 -0.306 -1.251 -1.039 -1.251 -1.854 s 0.495 -1.549 1.251 -1.854 L 44.251 0.146 c 0.48 -0.194 1.018 -0.194 1.498 0 l 41.593 16.803 c 0.756 0.306 1.251 1.039 1.251 1.854 s -0.495 1.549 -1.251 1.854 L 45.749 37.46 C 45.509 37.557 45.254 37.605 45 37.605 z"
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
        <path
          fill={fillColor}
          d="M 45 90 c -0.393 0 -0.783 -0.116 -1.119 -0.342 C 43.331 89.286 43 88.665 43 88 V 35.605 c 0 -0.815 0.495 -1.549 1.251 -1.854 l 41.593 -16.803 c 0.615 -0.249 1.316 -0.176 1.867 0.196 c 0.552 0.372 0.882 0.993 0.882 1.658 v 52.395 c 0 0.815 -0.495 1.549 -1.251 1.854 L 45.749 89.854 C 45.508 89.952 45.253 90 45 90 z M 47 36.955 v 48.081 l 37.593 -15.187 V 21.768 L 47 36.955 z"
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
        <path
          fill={fillColor}
          d="M 45 90 c -0.253 0 -0.508 -0.048 -0.749 -0.146 L 2.659 73.052 c -0.756 -0.306 -1.251 -1.039 -1.251 -1.854 V 18.803 c 0 -0.665 0.331 -1.286 0.882 -1.658 c 0.55 -0.372 1.251 -0.446 1.867 -0.196 l 16.075 6.495 c 1.024 0.414 1.519 1.58 1.105 2.604 c -0.414 1.025 -1.578 1.52 -2.604 1.105 L 5.408 21.768 v 48.081 L 43 85.035 V 36.955 l -14.825 -5.989 c -1.024 -0.414 -1.519 -1.58 -1.105 -2.604 c 0.414 -1.024 1.58 -1.519 2.604 -1.105 l 16.076 6.494 C 46.505 34.057 47 34.79 47 35.605 V 88 c 0 0.665 -0.33 1.286 -0.882 1.658 C 45.783 89.884 45.393 90 45 90 z"
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
        <path
          fill={fillColor}
          d="M 28.924 53.036 c -0.253 0 -0.507 -0.048 -0.749 -0.146 l -9.441 -3.813 c -0.756 -0.306 -1.251 -1.039 -1.251 -1.854 V 25.297 c 0 -0.815 0.495 -1.549 1.251 -1.854 L 60.326 6.64 c 0.48 -0.194 1.018 -0.194 1.498 0 l 9.441 3.814 c 0.756 0.306 1.251 1.039 1.251 1.854 s -0.495 1.549 -1.251 1.854 L 30.924 30.46 v 20.576 c 0 0.665 -0.331 1.286 -0.881 1.658 C 29.708 52.92 29.317 53.036 28.924 53.036 z M 21.483 45.874 l 5.441 2.198 v -18.96 c 0 -0.815 0.495 -1.549 1.251 -1.854 l 37.002 -14.948 l -4.103 -1.657 L 21.483 26.646 V 45.874 z"
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
        <path
          fill={fillColor}
          d="M 52.372 78.616 c -0.792 0 -1.541 -0.473 -1.855 -1.252 c -0.414 -1.024 0.081 -2.189 1.105 -2.604 l 9.44 -3.813 c 1.025 -0.411 2.19 0.081 2.604 1.105 c 0.414 1.024 -0.081 2.189 -1.105 2.604 l -9.44 3.813 C 52.875 78.568 52.621 78.616 52.372 78.616 z"
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
        <path
          fill={fillColor}
          d="M 52.372 71.526 c -0.792 0 -1.541 -0.473 -1.855 -1.252 c -0.414 -1.023 0.081 -2.189 1.105 -2.604 l 9.44 -3.814 c 1.025 -0.411 2.19 0.081 2.604 1.105 c 0.414 1.023 -0.081 2.189 -1.105 2.604 l -9.44 3.814 C 52.875 71.479 52.621 71.526 52.372 71.526 z"
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
        <path
          fill={fillColor}
          d="M 52.372 64.436 c -0.792 0 -1.541 -0.473 -1.855 -1.252 c -0.414 -1.024 0.081 -2.189 1.105 -2.604 l 9.44 -3.813 c 1.025 -0.412 2.19 0.081 2.604 1.105 c 0.414 1.024 -0.081 2.189 -1.105 2.604 l -9.44 3.813 C 52.875 64.388 52.621 64.436 52.372 64.436 z"
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
      </g>
    </svg>
  );
};

export default Products;
