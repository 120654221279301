import { FC } from "react";
import ProductCard from "../productCard/productCard";
import { List } from "./productList.styled";
interface IProductListProps {
  productArr: Array<any>
}

const ProductList: FC<IProductListProps> = (props) => {
  // #f9f9f9
  const {productArr} = props;


  return (
    <div style={{margin: "10px 0"}}>
      <List>
        {productArr.map((item, i) => (
          <ProductCard key={i} product={item} />
        ))}
      </List>
    </div>
  );
};

export default ProductList;
