import { FC } from "react";
import { IconProps } from "./interface";

const HorizontalBars: FC<IconProps> = (props) => {
  const { fillColor, size, title } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" height={size}>
      {title && <title>{title}</title>}
      <g>
        <path
          fill={fillColor}
          stroke="rgba(0, 0, 0, 0.55)"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M4 7h22M4 15h22M4 23h22"
        />
      </g>
    </svg>
  );
};

export default HorizontalBars;
