import ProductList from "./product.json";

export const getAllProducts = (pn: number = 1, limit: number = 10) => {
  const fromIndex = limit * (pn - 1);
  if (fromIndex >= ProductList.length)
    return { productCount: null, productList: [] };
  return {
    productCount: pn === 1 ? ProductList.length : null,
    productList: ProductList.slice(fromIndex, fromIndex + limit),
  };
};

export const getAllProductsKeyword = (
  keyword: string,
  pn: number = 1,
  limit: number = 10
) => {
  const kw = keyword.toLowerCase();
  const fromIndex = limit * (pn - 1);
  const filteredList = ProductList.filter(
    (item) =>
      item.name.toLowerCase().includes(kw) ||
      item.brand.toLowerCase().includes(kw) ||
      item.category.toLowerCase().includes(kw)
  );
  if (fromIndex >= filteredList.length)
    return { productCount: null, productList: [] };
  return {
    productCount: pn === 1 ? filteredList.length : null,
    productList: filteredList.slice(fromIndex, fromIndex + limit),
  };
};

export const getProductById = (id: string) => {
  return ProductList.find((item) => item.id === id);
};

export const getAllOrders = (pn: number = 1, limit: number = 10) => {
  const orderList = new Array(limit).fill(null).map((_, i) => {
    const price = Math.floor(Math.random() * 10000) / 100;
    const quantity = Math.ceil(Math.random() * 10);
    return {
      id: `O0${i}`,
      productId: `P0${i}`,
      orderId: `ON0${i}`,
      price,
      quantity,
      shipped: false,
      shippingDate: "",
      delivered: false,
      deliveryDate: "",
      total: (price * quantity).toFixed(2),
      shippingCharge: 0,
    };
  });
  return {
    orderCount: pn === 1 ? 60 : null,
    orderList,
  };
};
