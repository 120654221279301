import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateProductCount, updateProductDetail, updateProductList } from "../redux/slices/productSlice";
import { fetchAllProductList, fetchProductDetail, fetchProductListBySearchString, IProductResponse } from "../service/productService";

export const getProducts = createAsyncThunk(
  "product/getProducts",
  async (obj: any, { dispatch }) => {
      let productData: IProductResponse;
      const {searchString, pn, limit} = obj
      if (searchString) {
        productData = await fetchProductListBySearchString(searchString, pn, limit)
      } else {
        productData = await fetchAllProductList(pn, limit)
      }
      if (productData.productCount) {
        dispatch(updateProductCount(productData.productCount))
    
      }
      if (productData.productList) {
        dispatch(updateProductList(productData.productList))
      }
  }
);

export const getProductDetail = createAsyncThunk(
  "product/getProductDetail",
  async (obj: any, { dispatch }) => {
    const {productId} = obj;
    const productDetail = await fetchProductDetail(productId)
    if (Object.keys(productDetail).length) {
      dispatch(updateProductDetail({ productId, productDetail }))
    }
  }
);
