import { FC, useEffect, useState } from 'react'
import OrderList from 'src/components/merchant/orderList';
import Pagination from 'src/components/pagination/pagination';
import { getOrders } from 'src/lib/action/orderAction';
import { useAppDispatch, useAppSelector } from 'src/lib/redux/hooks';

const columns = ["orderId", "productId", "price", "quantity", "shippingCharge", "total", "shippingDate"];

const Order: FC = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const orderList = useAppSelector(
    (state) => state?.order?.orderList
  );
  const orderCount = useAppSelector(
    (state) => state?.order?.orderCount
  );
  useEffect(() => {
    setCurrentPage(1);
  }, [orderCount]);

  useEffect(() => {
    dispatch(getOrders(currentPage, 10));
  }, [currentPage]);

  return (
    <>
    <OrderList orderList={orderList} columns={columns} />
    <Pagination
      currentPage={currentPage}
      totalItem={orderCount}
      onChange={(pn) => {
        setCurrentPage(pn);
      }}
      itemPerPage={10}
    />
  </>
  )
}

export default Order;