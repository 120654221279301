import { useEffect, useState } from "react";
import Pagination from "src/components/pagination/pagination";
import ProductList from "src/components/productList/productList";
import { getProducts } from "src/lib/action/productAction";
import { useAppDispatch, useAppSelector } from "src/lib/redux/hooks";
import { updateSearchString } from "src/lib/redux/slices/productSlice";

const ProductPage = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const searchString = useAppSelector((state) => state?.product?.searchString)
  const productList = useAppSelector((state) => state?.product?.productList)
  const productCount = useAppSelector((state) => state?.product?.productCount)

  useEffect(() => {
    setCurrentPage(1)
  } ,[productCount])

  useEffect(()=>{
    dispatch(updateSearchString(''))
  },[])

  useEffect(() => {
      dispatch(getProducts({searchString, pn: currentPage,limit: 12}))
  }, [currentPage]);

  return (
    <>
      <ProductList productArr={productList} />
      <Pagination
        currentPage={currentPage}
        totalItem={productCount}
        onChange={(pn) => {
          setCurrentPage(pn);
        }}
        itemPerPage={12}
      />
    </>
  );
};

export default ProductPage;
