import styled from "styled-components";
import { colors } from "src/lib/colors";

export const Card = styled.div`
  background-color: #fff;
  border: 1px solid #f3f3f3;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  transition: 0.25s ease-in-out;
  &:hover {
    box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
    transform: translateY(-5px);
  }
`;

export const Thumbnail = styled.div`
  align-items: center;
  display: flex;
  height: 180px;
  justify-content: center;
  position: relative;
`;

export const Badge = styled.span`
  background-color: #212121;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  padding: 5px 10px;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
`;

export const ImageWrap = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const Image = styled.img`
  border-radius: 0 !important;
  height: auto;
  margin-bottom: 0 !important;
  max-height: 100%;
  max-width: 100%;
  width: auto;
`;

export const Description = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px 20px 20px;
  & a{
    text-decoration: none;
  }
`;

export const PriceWrap = styled.div`
  align-items: baseline;
  display: flex;
  font-weight: 600;
  margin-bottom: 5px;
`;

export const Price = styled.span`
  align-items: center;
  color: #212121;
  display: flex;
  flex-direction: row-reverse;
  font-size: 16px;
  margin-bottom: 0;
  margin-right: 10px;
`;

export const Discount = styled.span`
  color: ${colors?.textMuted};
  font-size: 13px;
  font-weight: 400;
  text-decoration: line-through;
`;

// export const Unit = styled.span`
//   font-size: 13px;
//   margin: 0 10px;
// `;

export const Title = styled.div`
  color: #5a5a5a;
  font-size: 13px;
  margin-bottom: 25px;
  transition: 0.15s ease-in-out;
  background-color: transparent;
  text-decoration: none;
  &:hover {
    color: #212121;
  }
`;
// export const ButtonIcon = styled.span`
//   align-items: center;
//   background-color: #e6e6e6;
//   display: flex;
//   justify-content: center;
//   transition: 0.15s ease-in-out;
//   width: 35px;
//   height: 100%;
//   &:hover {
//     background-color: inherit;
//     color: inherit;
//   }
// `;