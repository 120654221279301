import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "src/components/basic/button/button";
import ProductEditView from "src/components/merchant/productEdit";
import ProductDetail from "src/components/productDetail/productDetail";
import { getProductDetail } from "src/lib/action/productAction";
import { useAppDispatch, useAppSelector } from "src/lib/redux/hooks";

const ProductEdit: FC = () => {
  const { productId } = useParams();
  const dispatch = useAppDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const productDetailMap = useAppSelector(
    (state) => state?.product?.productDetail
  );
  const productDetail = productDetailMap[productId || '']

  useEffect(() => {
    dispatch(getProductDetail(productId || ''))
}, [productId]);

  return productDetail && Object.keys(productDetail).length > 0 ? (
    <>
      {isEditMode ? (
        <ProductEditView product={productDetail} onClose={() => setIsEditMode(false)} />
      ) : (
        <>
          <Button label="Edit" onClick={() => setIsEditMode(true)} />
          <ProductDetail isMerchant product={productDetail} />
        </>
      )}
    </>
  ): <></>;
};

export default ProductEdit;
