import axios from "src/lib/axios";
export interface OrderDto {
  id?: string;
  // name: string;
  // price: number;
  // priceMax: number;
  // availability: boolean;
  // isSale: boolean;
  // seller: string;
  // shippingCharge: number;
  // brand: string;
  // category: string;
}
export interface IOrderResponse {
  orderList: Array<OrderDto>;
  orderCount: number | null;
}

export const fetchOrderList = async (
  pn: number,
  limit: number
): Promise<IOrderResponse> => {
  try {
    const response = await axios(`/order?pageNumber=${pn}&limit=${limit}`);
    return response as any as IOrderResponse;
  } catch (error) {
    return {orderCount: null, orderList: []};
  }
};
