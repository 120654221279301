import { FC } from "react";
import { camelToTitle } from "src/lib/utils";
import Table from "../table/table";

interface IOrderListProps {
  orderList: Array<any>;
  columns: Array<string>;
}

const OrderList: FC<IOrderListProps> = (props) => {
  const { columns, orderList } = props;
  return (
    <>
      <Table type="table-striped">
        <thead>
          <tr>
            {columns.map((header: string) => (
              <th key={header}>{camelToTitle(header)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {orderList?.map((item: any) => (
            <tr key={item.id}>
              {columns.map((header) => (
                <td key={header}>{item[header]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default OrderList;
