import styled from "styled-components";

export const PaginationWrapper = styled.ul`
  ${({ theme }: { theme: any}) => `
    display: flex;
    list-style: none;
    padding: 10px;
    justify-content: center;
    & li {
      // flex: 1;
      margin: 0px 5px;
      // background: #dde1e7;
      background: #ffffff;
      border-radius: 3px;
      box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(94, 104, 121, 0.288);

      button {
        font-size: 18px;
        text-decoration: none;
        color: ${theme?.colors?.link};
        height: 45px;
        width: 55px;
        display: block;
        line-height: 45px;
        border: none;
        outline:none;
        background:white;
        cursor: pointer;

        &:disabled{
          cursor: default;
        }
        :focus-visible{
          outline: -webkit-focus-ring-color auto 1px;
        }
      }

      :first-child button {
        width: 120px;
      }
      :last-child button {
        width: 100px;
      }
      &.active {
        box-shadow: inset -3px -3px 7px #ffffff73,
          inset 3px 3px 5px rgba(94, 104, 121, 0.288);
      }
      .active button {
        font-size: 17px;
        color: #6f6cde;
      }
      :first-child {
        margin: 0 15px 0 0;
      }
      :last-child {
        margin: 0 0 0 15px;
      }
    }
    @media (max-width: 500px) {
      & li{
        display: none;
        :first-child {
          display: unset;
        }
        :last-child {
          display: unset;
        }
      }
    }
  `}
`;
