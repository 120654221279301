import { FC, useEffect, useState } from "react";
import {
  CarouselControl,
  CarouselWrapper,
  DotNavigator,
  Slide,
} from "./slideShow.styled";

interface ISlideShowProps {
  imageList: [any];
  interval?: number;
}

const SlideShow: FC<ISlideShowProps> = (props) => {
  const { interval = 3, imageList = [] } = props;
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    const t1 = setTimeout(()=>{
      nextSlide()
    }, interval * 1000)
    return () => {
      clearTimeout(t1)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide, imageList
  ])

  const nextSlide = () => {
    if(currentSlide === imageList.length-1){
      setCurrentSlide(0)
    } else {
      setCurrentSlide(currentSlide+1)
    }
  }

  const prevSlide = () => {
    if(currentSlide === 0){
      setCurrentSlide(imageList.length-1)
    } else {
      setCurrentSlide(currentSlide-1)
    }
  }

  return (
    <>
      <CarouselWrapper>
        {imageList.map((image, i) => (
          <Slide key={i} className={currentSlide === i ? "active" : ""}>
            <img src={image} alt="..." loading="lazy" decoding="async" />
          </Slide>
        ))}

        <CarouselControl>
          <button className="prev" onClick={prevSlide}>
            &#10094;
          </button>
          <button className="next" onClick={nextSlide}>
            &#10095;
          </button>
        </CarouselControl>
      </CarouselWrapper>

      <DotNavigator>
        {imageList.map((_, i) => (
          <span
            key={i}
            className={currentSlide === i ? "active dot" : "dot"}
            onClick={() => setCurrentSlide(i)}
          />
        ))}
      </DotNavigator>
    </>
  );
};

export default SlideShow;
