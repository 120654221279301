import { FC } from "react";

interface IPaymentMethodsProps {}

const PaymentMethods: FC<IPaymentMethodsProps> = (props) => {
  return (
    <div>
      <h5>Select a payment method</h5>
      <ul>
        <li>Debit Card</li>
        <li>Credit Card</li>
        <li>Paytm</li>
        <li>UPI</li>
        <li>Pay on Delivery</li>
      </ul>
    </div>
  );
};

export default PaymentMethods;
