import styled from "styled-components";
import { hexToRGB } from "src/lib/utils";

export const ButtonWrapper = styled.button`
  ${({ theme }: { theme: any}) => `
    min-width: 100px;
    border: none;
    border-radius: 6px;
    background-color: ${theme?.colors?.primaryButton};
    color: ${theme?.colors?.primaryButtonText};
    font-size: 14px;
    min-height: 35px;
    transition: 0.15s ease-in-out;
    padding: 0 15px;
    cursor: pointer;

    &.w100{
      width: 100%;
    }

    &.primary{
      background-color: ${theme?.colors?.primaryButton};
      color: ${theme?.colors?.primaryButtonText};
      &:hover {
        background-color: ${hexToRGB(theme?.colors?.primaryButton, 0.8)};
      }
      &:disabled,
      button[disabled]{
        background-color: ${hexToRGB(theme?.colors?.primaryButton, 0.5)};
        cursor: default;
      }
    }

    &.secondary{
      background-color: ${theme?.colors?.secondaryButton};
      color: ${theme?.colors?.secondaryButtonText};
      &:hover {
        background-color: ${hexToRGB(theme?.colors?.secondaryButton, 0.8)};
      }
      &:disabled,
      button[disabled]{
        background-color: ${hexToRGB(theme?.colors?.secondaryButton, 0.5)};
        cursor: default;
      }
    }

    &.icon{
      min-width: unset;
      background-color: transparent;
      padding: 0 5px;
      margin: 0 10px;
      &:hover {
        background-color: transparent;
      }
    }

    &.link{
      min-width: unset;
      background-color: transparent;
      padding: 0;
      margin: 0;
      color: ${theme?.colors?.link};
      &:hover {
        background-color: transparent;
      }
    }

  `}
`;

export const StyledButtonGroup = styled.div`
  ${({ orientation }: { orientation: string }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    flex-direction: ${orientation === 'vertical' ? 'column' : 'row'};
  `}
`;
