import { FC } from "react";
import { IconProps } from "./interface";

const Delete: FC<IconProps> = (props) => {
  const { fillColor, size, title } = props;
  return (
    <svg
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
    >
      {title && <title>{title}</title>}
      <path
        fill={fillColor}
        d="M341.333333 170.666667l42.666667-85.333334h256l42.666667 85.333334h213.333333v85.333333H128V170.666667h213.333333zM213.333333 298.666667h597.333334v554.666666a85.333333 85.333333 0 0 1-85.333334 85.333334H298.666667a85.333333 85.333333 0 0 1-85.333334-85.333334V298.666667z m85.333334 85.333333v469.333333h426.666666V384H298.666667z m85.333333 85.333333h85.333333v298.666667H384v-298.666667z m170.666667 0h85.333333v298.666667h-85.333333v-298.666667z"
      />
    </svg>
  );
};

export default Delete;
