import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import Footer from "src/components/footer/footer";
import MerchantLayout from "src/components/merchant/layout";
import Navbar from "src/components/navbar/navbar";
import { themeLight } from "src/lib/colors";
import Checkout from "src/pages/checkout";
import Home from "src/pages/home";
import MerchantDashboard from "src/pages/merchant/dashboard";
import MerchantOrder from "src/pages/merchant/order";
import MerchantProductList from "src/pages/merchant/productList";
import ProductEdit from "src/pages/merchant/productEdit";
import ProductPage from "src/pages/product";
import ProductDetail from "src/pages/productDetail";
import Test from "src/pages/test-page";
import { useAppDispatch, useAppSelector } from "src/lib/redux/hooks";
import { updateTheme, USER_TYPE } from "src/lib/redux/slices/mainSlice";

function App() {
  const dispatch = useAppDispatch();
  // const pathname = window.location.pathname || '';
  const userType = useAppSelector((state) => state?.main?.userType)
  
  useEffect(() => {
    dispatch(updateTheme(themeLight));
  }, []);

  // console.log('<<<<<<<======= App.tsx Rendered ======>>>>>>>>.');

  return (
    <Router>
      <div style={{ height: "100vh" }}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/test" element={<Test />} />
          <Route path="/product" element={<ProductPage />} />
          <Route path="/product/:productId" element={<ProductDetail />} />
          <Route path="/checkout" element={<Checkout />} />
        </Routes>
        {/* Merchant Routes */}
        {userType === USER_TYPE.MERCHANT && (
          <MerchantLayout>
            <Routes>
              <Route path="/merchant/dashboard" element={<MerchantDashboard />} />
              <Route path="/merchant/product" element={<MerchantProductList />} />
              <Route path="/merchant/order" element={<MerchantOrder />} />
              <Route path="/merchant/product/:productId" element={<ProductEdit />} />
            </Routes>
          </MerchantLayout>
        )}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
