import { FC, useState } from "react";
import Button, { ButtonGroup } from "src/components/basic/button/button";
import TextField from "src/components/basic/textField/textField";
import Modal from "src/components/modal/modal";

interface IAddressProps {
  open: boolean;
  onClose: () => void;
}

const initialFormValue = {
  country: "",
  name: "",
  mobileNo: "",
  pin: "",
  apartment: "",
  colony: "",
  landmark: "",
  city: "",
  state: ""
};
const AddressModal: FC<IAddressProps> = (props) => {
  const { onClose, open } = props;
  const [formValue, setFormValue] = useState(initialFormValue);

  const handleFormChange = (e:any) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = () => {
    console.log(formValue)
  }

  return (
    <Modal header="Enter a new delivery address" open={open} onClose={onClose}>
      <form>
        <TextField id="country" label="Country" fullWidth value={formValue.country} name="country" onChange={handleFormChange} />
        <TextField id="name" label="Full Name" fullWidth value={formValue.name} name="name" onChange={handleFormChange} />
        <TextField id="mobileNo" label="Mobile Number" fullWidth value={formValue.mobileNo} name="mobileNo" onChange={handleFormChange} />
        <TextField id="pin" label="Pin Code" fullWidth value={formValue.pin} name="pin" onChange={handleFormChange} />
        <TextField id="apartment" label="Apartment" fullWidth value={formValue.apartment} name="apartment" onChange={handleFormChange} />
        <TextField id="colony" label="Colony" fullWidth value={formValue.colony} name="colony" onChange={handleFormChange} />
        <TextField id="landmark" label="Landmark" fullWidth value={formValue.landmark} name="landmark" onChange={handleFormChange} />
        <TextField id="city" label="City" fullWidth value={formValue.city} name="city" onChange={handleFormChange} />
        <TextField id="state" label="State" fullWidth value={formValue.state} name="state" onChange={handleFormChange} />
        <ButtonGroup>
          <Button label="Add address" onClick={handleSubmit} />
        </ButtonGroup>
      </form>
    </Modal>
  );
};

export default AddressModal;
