import { FC, useEffect, useState } from "react";
import MerchantProductListView, { ColumnAlignType } from "src/components/merchant/productList";
import Pagination from "src/components/pagination/pagination";
import { getProducts } from "src/lib/action/productAction";
import { useAppDispatch, useAppSelector } from "src/lib/redux/hooks";
import { updateSearchString } from "src/lib/redux/slices/productSlice";


const columnInfo = [
  {name:'name'},
  {name:'priceMax', align: ColumnAlignType.Right},
  {name:'price', align:  ColumnAlignType.Right},
  {name:'currency', align:  ColumnAlignType.Center},
  {name:'action'},
];
const ProductList: FC = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const searchString = useAppSelector(
    (state) => state?.product?.searchString
  );
  const productList = useAppSelector(
    (state) => state?.product?.productList
  );
  const productCount = useAppSelector(
    (state) => state?.product?.productCount
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [productCount]);

  useEffect(() => {
    dispatch(updateSearchString(""));
  }, []);

  useEffect(() => {
    dispatch(getProducts({searchString, pn:currentPage, limit:12}));
  }, [currentPage]);

  return (
    <>
      <MerchantProductListView productList={productList} columnInfo={columnInfo} />
      <Pagination
        currentPage={currentPage}
        totalItem={productCount}
        onChange={(pn) => {
          setCurrentPage(pn);
        }}
        itemPerPage={12}
      />
    </>
  );
};

export default ProductList;
