import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch } from "src/lib/redux/hooks";
import { addToCart } from "src/lib/redux/slices/cartSlice";
import { getCurrencySymbol } from "src/lib/utils";
import Button from "src/components/basic/button/button";
import Snackbar from "src/components/snackbar/snackbar";
import {
  Badge,
  Card,
  Description,
  Discount,
  Image,
  ImageWrap,
  Price,
  PriceWrap,
  Thumbnail,
  Title,
} from "./productCard.styled";

interface IProductCardProps {
  product: any;
}

const ProductCard: FC<IProductCardProps> = (props) => {
  const { product } = props;
  const dispatch = useAppDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarLabel, setSnackbarLabel] = useState('')

  const addProduct = () => {
    setSnackbarLabel(`${product.name} added to your cart`)
    setShowSnackbar(true)
    dispatch(
      addToCart({
        id: product.id,
        quantity: 1,
        name: product.name,
        thumbnail: product.imageURLs[0],
        price: product.price,
        currency: product.currency,
      })
    );
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false)
    setSnackbarLabel('')
  }

  return (
    <>
    <Card>
      <Thumbnail>
        {product.isSale && <Badge>Sale</Badge>}
        <ImageWrap>
          <Image src={product.imageURLs[0]} alt={product.name} />
        </ImageWrap>
      </Thumbnail>
      <Description>
        <div>
        <PriceWrap>
          <Price>{getCurrencySymbol(product.currency)}{product.price}</Price>
          <Discount>{getCurrencySymbol(product.currency)}{product.priceMax}</Discount>
          {/* <Unit>unit</Unit> */}
        </PriceWrap>
        <Link to={`/product/${product.id}`} >
          <Title>
          {product.name}
          </Title>
          </Link>
        </div>
          <Button label="ADD" onClick={addProduct} fullWidth />
      </Description>
    </Card>
    <Snackbar show={showSnackbar} label={snackbarLabel} onClose={handleCloseSnackbar} />
    </>
  );
};

export default ProductCard;
