import { FC, ReactNode } from "react";
import { useAppSelector } from "src/lib/redux/hooks";
import { ButtonWrapper, StyledButtonGroup } from "./button.styled";

interface IButtonProps {
  label: string | ReactNode;
  onClick?: () => void;
  type?: "primary" | "secondary" | "icon" | "link";
  disabled?: boolean;
  fullWidth?: boolean;
  submit?: boolean;
  ariaLabel?: string
}

const Button: FC<IButtonProps> = (props) => {
  const {
    disabled = false,
    label,
    onClick = () => {},
    type = "primary",
    fullWidth = false,
    submit = false,
    ariaLabel,
  } = props;
  const theme = useAppSelector((state) => state?.main?.theme);

  const onButtonClick = (e:any) => {
    e.preventDefault()
    onClick()
  }

  return (
    <ButtonWrapper
      className={fullWidth ? `${type} w100` : type}
      theme={theme}
      onClick={onButtonClick}
      disabled={disabled}
      type={submit? "submit": "button"}
      aria-label={ariaLabel}
    >
      {label}
    </ButtonWrapper>
  );
};

interface IButtonGroupProps {
  orientation?: "horizontal" | "vertical";
  children: ReactNode;
}

export const ButtonGroup: FC<IButtonGroupProps> = (props) => {
  const { children, orientation = "horizontal" } = props;
  return (
    <StyledButtonGroup orientation={orientation}>{children}</StyledButtonGroup>
  );
};

export default Button;
