import styled from "styled-components";

export const TableWrapper = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
  caption-side: bottom;
  border-collapse: collapse;
  &.table-striped>tbody>tr:nth-of-type(odd)>* {
    color: #212529;
    background-color: #0000000d;
  }
  & > :not(:first-child) {
    border-top: 2px solid currentColor;
  }

  & tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
  & > thead {
    vertical-align: bottom;
  }
  & > tbody {
    vertical-align: inherit;
  }
  & th, td {
    padding: 0.5rem 0.5rem;
    // background-color: #f8f9fa;
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px transparent;
  }
`;
