import styled from "styled-components";

export const FooterUI = styled.footer`
  ${({ theme }: { theme: any}) => `
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
    background-color: ${theme?.colors?.footer};
    color: #212121; //#212121
    overflow: hidden;
    position: relative;
    // width: 100%;
  `}
`;

export const Info = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 75px;
  @media (max-width: 1440px) {
    padding: 20px 50px;
  }
  @media (max-width: 1280px) {
    padding: 20px 40px;
  }
  @media (max-width: 1024px) {
    padding: 20px;
  }
  @media (max-width: 599px) {
    padding: 15px;
    font-size: 14px;
  }
  @media (max-width: 399px) {
    padding: 10px;
  }
`;

export const Copyright = styled.div`
  // padding-right: 20px;
  @media (max-width: 450px) {
    display: none;
  }
`;

export const Social = styled.div`
  flex-shrink: 0;
  // padding-left: 20px;
`;

export const IconList = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const IconItem = styled.li`
  margin: 0 10px 0 0;
  & > a {
    color: #212121;
    display: block;
    font-size: 16px;
    line-height: 1;
    margin: 0;
    text-decoration: none;
    transition: 0.15s ease-in-out;
  }
`;
