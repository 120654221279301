import styled from "styled-components";


export const TwoColumnGrid = styled.div`
  margin: 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
 }
`;