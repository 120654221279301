import axios from "axios";
import { getAllOrders, getAllProducts, getAllProductsKeyword, getProductById } from "./productQuery";

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
  timeout: 1000,
});

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // if(error.response.status === 404){
    if(error.config.url){
      switch (error.config.url.split('?')[0]) {
        case '/product':
          const temp1 = JSON.parse(`{"${error.config.url.split('?')?.[1]}"}`?.replace(/=/g, '":"')?.replace(/&/g, '","'))
          return getAllProducts(Number(temp1.pageNumber), Number(temp1.limit));

          case '/order':
            const temp3 = JSON.parse(`{"${error.config.url.split('?')?.[1]}"}`?.replace(/=/g, '":"')?.replace(/&/g, '","'))
            return getAllOrders(Number(temp3.pageNumber), Number(temp3.limit));
          
          case '/product/search':
          const temp2 = JSON.parse(`{"${error.config.url.split('?')?.[1]}"}`?.replace(/=/g, '":"')?.replace(/&/g, '","'))
          return getAllProductsKeyword(temp2.text, Number(temp2.pageNumber), Number(temp2.limit));

        default:
          if(error.config.url.startsWith('/product/')){
            return getProductById(error.config.url.split('/product/')[1])
          }
          break;
      }
      // console.log(error)

    }
    return Promise.reject(error);
  }
);

export default instance;
