import { FC, useEffect, useState } from "react";
import Dropdown from "src/components/basic/dropdown/dropdown";
import Textarea from "src/components/basic/textarea/textarea";
import Button, { ButtonGroup } from "src/components/basic/button/button";
import TextField from "src/components/basic/textField/textField";
import { currencySymbols } from "src/lib/constants";

interface IProductEditProps {
  product: any;
  onClose: () => void;
}

const ProductEditView: FC<IProductEditProps> = (props) => {
  const { onClose, product } = props;
  const [formValue, setFormValue] = useState<any>({});

  useEffect(() => {
    setFormValue(product);
  }, [product]);

  const handleValueChange = (e: any) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
    <h3>Edit Product Details</h3>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-1">Name</div>
          <div className="col-12 col-md-11">
            <Textarea
              id="name"
              label="Name"
              name="name"
              value={formValue?.name || ""}
              onChange={handleValueChange}
              fullWidth
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4">
            <TextField
              id="price"
              label="Price"
              name="price"
              value={formValue?.price || ""}
              onChange={handleValueChange}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <TextField
              id="priceMax"
              label="Max Price"
              name="priceMax"
              value={formValue?.priceMax || ""}
              onChange={handleValueChange}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <Dropdown
              label="Currency"
              value={formValue?.currency || ""}
              options={Object.keys(currencySymbols)}
              onChange={(value) =>
                setFormValue({
                  ...formValue,
                  currency: value,
                })
              }
            />
          </div>
        </div>
        <div className="row"></div>
      </div>
      <ButtonGroup>
        <Button label="Save" onClick={onClose} />
        <Button type="secondary" label="Cancel" onClick={onClose} />
      </ButtonGroup>
    </>
  );
};

export default ProductEditView;
