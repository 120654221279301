export const themeLight = {
  colors:{
    icon: "#1d3557", // #9400d3, #1d3557  ef820d  fda50f
    logo: "#E42131", // #7fc37e
    link: "#0000EE",
    pageBackground: "#f1faee",  // f1faee
    navbar: "#a8dadc", // #f8f9fa
    footer: "#f1faee",  // 457b9d
    primaryButton: "#E42131",
    primaryButtonText: "#fff",
    secondaryButton: "#6C757D",
    secondaryButtonText: "#fff",
  }
}

export const colors = {
  textMuted: "#757575"
}