import styled from "styled-components";

export const DropdownWrapper = styled.div`
  position: relative;
  margin: 25px 0 10px;
`;

export const DropdownButton = styled.button`
  background-color: transparent;
  padding: 6px 15px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  cursor: pointer;
  &:hover,
  :focus {
    // background-color: #2980b9;
  }
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  // min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  &.show {
    display: block;
  }
`;

export const DropdownItem = styled.div`
  color: black;
  padding: 12px 16px;
  display: block;

  &:hover {
    background-color: #ddd;
  }
`;

export const DropdownLabel = styled.div`
  font-size: 0.8rem;
  position: absolute;
  top: -15px;
`;

