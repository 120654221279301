import { FC, useEffect, useState } from "react";
import {
  DropdownButton,
  DropdownContent,
  DropdownItem,
  DropdownLabel,
  DropdownWrapper,
} from "./dropdown.styled";

interface IDropdownProps {
  label: string
  value: string | number;
  options: Array<string| number>
  onChange: (value: string| number) => void
}

const Dropdown: FC<IDropdownProps> = (props) => {
  const {label, onChange, options, value} = props;

  const [show, setShow] = useState(false);

  useEffect(() => {
    function outsideClick(event: any) {
      if (!event.target.matches(".dropdown-btn")) {
        setShow(false);
      }
    }

    window.addEventListener("click", outsideClick);

    return () => window.removeEventListener("click", outsideClick);
  }, []);

  return (
    <DropdownWrapper>
      {options.includes(value) && <DropdownLabel>{label}</DropdownLabel>}
      <DropdownButton
        className="dropdown-btn"
        onClick={() => {
          setShow(!show);
        }}
      >
        {options.includes(value)? value : 'Select '+label} {show ? <>&#8963;</> : <>&#8964;</>}
      </DropdownButton>
      <DropdownContent className={`${show ? "show" : ""}`}>
        {options.map(item=><DropdownItem key={item} onClick={()=>onChange(item)}>{item}</DropdownItem>)}
      </DropdownContent>
    </DropdownWrapper>
  );
};

export default Dropdown;
