import { configureStore, ThunkAction, Action, combineReducers, PreloadedState } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';

import mainSlice from "./slices/mainSlice";
import cartSlice from "./slices/cartSlice";
import orderSlice from "./slices/orderSlice";
import productSlice from "./slices/productSlice";

const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    encryptTransform({
      secretKey: 'OgEvcU1TU1iw8M8Lxz$nNoh',
      onError: function (error:any) {
        // Handle the error.
      },
    }),
  ],
}

const rootReducer = combineReducers({
  main: mainSlice,
  cart: cartSlice,
  order: orderSlice,
  product: productSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  })
});

export const testStore = (preloadedState?: PreloadedState<RootState>) => configureStore({reducer: rootReducer, preloadedState, middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  })
})

export const persistor = persistStore(store)

// TODO: Need to add logger middle ware for dev environment

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
