import { FC, useState } from 'react'
import Button from 'src/components/basic/button/button';
import AddressModal from 'src/components/modal/address/address';
import PaymentMethods from 'src/components/paymentMethods/paymentMethods';

interface ICheckoutStepsProps {
  
}

const CheckoutSteps: FC<ICheckoutStepsProps> = (props) => {
  const [showAddressModal, setShowAddressModal] = useState(false);

    return (
        <>
          <div>
            <h3>Delivery Address</h3>
            <h5>Your Addresses</h5>
            <Button label="Add a new address" onClick={()=>setShowAddressModal(true)} />
          </div>
          <div>
            <PaymentMethods />
          </div>
          <AddressModal open={showAddressModal} onClose={()=>setShowAddressModal(false)} />
        </>
      )
}

export default CheckoutSteps;