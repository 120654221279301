import { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Icon from "src/icons/Icon";
import { camelToTitle } from "src/lib/utils";
import Button from "src/components/basic/button/button";
import Table from "src/components/table/table";
export enum ColumnAlignType {
  Right = "right",
  Center = "center"
}
interface ColumnInfo {name: string, align?: ColumnAlignType}
interface IProductProps {
  productList: Array<any>;
  columnInfo: Array<ColumnInfo>;
}

const MerchantProductListView: FC<IProductProps> = (props) => {
  const navigate = useNavigate();
  const { columnInfo, productList } = props;
  return (
    <>
      <Table type="table-striped">
        <thead>
          <tr>
            {columnInfo.map((columnItem: ColumnInfo) => (
              <th key={columnItem.name}>{camelToTitle(columnItem.name)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {productList?.map((item: any) => (
            <tr key={item.id}>
              {columnInfo.map((columnItem) =>
                columnItem.name === "action" ? (
                  <td key={columnItem.name}>
                    <IconWrapper>
                      <Button type="icon" label={<Icon name="edit" />} onClick={() => navigate(`/merchant/product/${item.id}`)} />
                      <Button type="icon" label={<Icon name="delete" />} onClick={() => {}} />
                    </IconWrapper>
                  </td>
                ) : (
                  <td style={{textAlign: columnItem.align || 'left'}} key={columnItem.name}>{item[columnItem.name]}</td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default MerchantProductListView;

const IconWrapper = styled.div`
  ${({ theme }: { theme: any }) => `
    display: flex;
    gap: 5px;
    & button {
      margin: 0!important;
    }
  `}
`;
