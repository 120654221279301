import { FC } from "react";
import Icon from "src/icons/Icon";
import { useAppSelector } from "src/lib/redux/hooks";
import {
  Copyright,
  FooterUI,
  IconItem,
  IconList,
  Info,
  Social,
} from "./footer.styled";

interface IFooterProps {}
const iconList = [
  { icon: "facebook", link: "#" },
  { icon: "instagram", link: "#" },
  { icon: "linkedin", link: "#" },
  { icon: "twitter", link: "#" },
];

const Footer: FC<IFooterProps> = (props) => {
  // console.log("~~~~ Footer rendered");

  const theme = useAppSelector((state) => state?.main?.theme)

  return (
    // <FooterUI>
    <FooterUI theme={theme}>
      <Info>
        <Copyright>
          © Himanshu Gupta {new Date().getFullYear()}. All Rights Reserved.
        </Copyright>
        <Social>
          <IconList>
            {iconList.map((item) => (
              <IconItem key={item.icon}>
                <a href={item.link} title={item.icon} target="_blank" rel="noreferrer">
                  <Icon size="sm" name={item.icon} />
                </a>
              </IconItem>
            ))}
          </IconList>
        </Social>
      </Info>
    </FooterUI>
  );
};

export default Footer;
