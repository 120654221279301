import styled from "styled-components";

export const List = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 10px;
  gap: 10px;
  @media (min-width: 400px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (min-width: 1025px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media (min-width: 1400px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  @media (min-width: 1800px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
`;
