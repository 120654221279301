import styled from "styled-components";

export const SnackBarWrapper = styled.div`
  visibility: hidden;
  min-width: 100px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 0 5px;
  position: fixed;
  z-index: 100;
  bottom: 30px;
  right: 10px;
  font-size: 0.8em;

  &.info{
    background-color: #333;
  }
  &.warning{
    background-color: #333;
  }
  &.error{
    background-color: #333;
  }

  &.show {
    visibility: visible;
    animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
  }

  @keyframes fadeIn {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      bottom: 30px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }
`;
