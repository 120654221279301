import styled from "styled-components";
import { colors } from "src/lib/colors";

export const CartItem = styled.li`
  align-items: center;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  padding: 20px;
  position: relative;
`;

export const Thumbnail = styled.div`
  align-self: center;
  border-radius: 6px;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  width: 100px;
  max-height: 100px;
  display: flex;
  justify-content: center;
  & > img {
    display: block;
    margin: 0;
    // height: auto;
    width: auto;
    height: 100px;
    object-fit: cover;
  }
`;

export const Iteminfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 0 15px;
`;

export const Title = styled.a`
  color: #5a5a5a;
  display: block;
  font-size: 13px;
  margin-bottom: 5px;
  transition: 0.15s ease-in-out;
  background-color: transparent;
  text-decoration: none;
`;

export const ItemPrice = styled.span`
  color: ${colors?.textMuted};
  display: block;
  font-size: 13px;
  margin-bottom: 8px;
`;

export const ItemCountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemCounter = styled.div`
  box-shadow: 0 5px 10px rgb(0 0 0 / 16%);
  border-radius: 6px;
  display: inline-flex;
`;

export const ItemTotal = styled.div`
  font-weight: 600;
  line-height: 1.2;
`;

export const UpdateButton = styled.span`
  background-color: #212121;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  transition: 0.15s ease-in-out;
  height: 35px;
  width: 35px;
  &:hover {
    background-color: #3a3a3a;
  }
  &.decrement {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  &.increment {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;

export const ItemCount = styled.div`
  align-items: center;
  color: #5a5a5a;
  display: flex;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 1;
  width: 45px;
  background-color: #212121;
  color: #fff;
`;
