import axios from "src/lib/axios";
enum Currency {
    EUR = 'EUR',
    GBP = 'GBP',
    INR = 'INR',
    USD = 'USD',
  }
export interface ProductDto {
    id?: string;
    name: string;
    price: number;
    priceMax: number;
    availability: boolean;
    isSale: boolean;
    currency: Currency;
    seller: string;
    shippingCharge: number;
    brand: string;
    category: string;
    imageURLs: string[];
  }
  
  export interface IProductResponse {
    productCount: number | null;
    productList: Array<ProductDto>;
  }

export const fetchProductListBySearchString = async (
  text: string,
  pn: number,
  limit: number
): Promise<IProductResponse> => {
  try {
    const response = await axios(
      `/product/search?text=${text}&pageNumber=${pn}&limit=${limit}`
    );
    return response as any as IProductResponse;
  } catch (error) {
    return {productCount:null, productList: []};
  }
};

export const fetchAllProductList = async (
  pn: number,
  limit: number
): Promise<IProductResponse> => {
  try {
    const response = await axios(`/product?pageNumber=${pn}&limit=${limit}`);
    return response as any as IProductResponse;
  } catch (error) {
    return {productCount:null, productList: []};
  }
};

export const fetchProductDetail = async (
  productId: string
): Promise<any> => {
  try {
    const response = await axios(`/product/${productId}`);
    return response;
  } catch (error) {
    return {};
  }
};