import styled from "styled-components";
import { colors } from "src/lib/colors";

export const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
  letter-spacing: 0.2px;
`;
export const PriceRow = styled.div`
  margin-bottom: 20px;
  align-items: center;
  color: #212121;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  margin-top: 0;
  line-height: 1.625;
  word-break: break-word;
`;
export const Detail = styled.div`
  & > p {
    color: #5a5a5a;
    margin-bottom: 1.5em;
    word-break: break-word;
  }
`;

export const Discount = styled.span`
  color: ${colors?.textMuted};
  font-size: 13px;
  font-weight: 400;
  text-decoration: line-through;
`;
