import styled from "styled-components";
import { colors } from "src/lib/colors";

export const FormControlWrapper = styled.div`
  position: relative;
  margin: 25px 0 10px;

  & input{
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: none;
    height: 30px;
    font-size: 16px;
    margin-bottom: 2px;
    padding: 0;
    box-shadow: none;
    box-sizing: content-box;
    transition: box-shadow .3s, border .3s, -webkit-box-shadow .3s;
    &.w100{
      width: 100%;
    }
  }
  & >label {
    color: ${colors?.textMuted};
    position: absolute;
    top: -10px;
    left: 0;
    font-size: 1rem;
    cursor: text;
    transition: transform .2s ease-out, color .2s ease-out, -webkit-transform .2s ease-out;
    transform-origin: 0% 100%;
    text-align: initial;
    transform: translateY(12px);
    &:after{
      display: block;
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      opacity: 0;
      transition: .2s opacity ease-out, .2s color ease-out;
    }

    &.active {
      transform: translateY(-5px) scale(0.8);
      transform-origin: 0 0;
      color: #000;
    }
  }

  & .helper-text, .error-text {
    position: relative;
    min-height: 18px;
    display: block;
    font-size: 12px;
    color: rgba(0,0,0,0.54);
  }

  & .error-text {
    color: #F44336;
  }

`

export const IconInputWrapper = styled.div`
  display: inline-flex;
  box-shadow: 0 0 0 1px #000, 0 0 0 1px rgb(0 0 0 / 50%);

  &.focus{
    box-shadow: 0 0 0 2px #000, 0 0 0 3px rgb(0 0 0 / 50%);
  }
  
  & .icon-container{
    border: none;
    // border-left: none;
    background-color: lightgrey;
    padding: 5px 10px;
  }
  & input, input:focus {
    border: none;
    outline: none;
  }
`

