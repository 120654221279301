import { FC } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Icon from "src/icons/Icon";

interface ILeftSidebarProps {}

const LeftSidebar: FC<ILeftSidebarProps> = (props) => {
  return (
    <NavList>
      <li>
        
        <Link to="/merchant/dashboard">
          <Icon name="dashboard" />
          <span>Dashboard</span>
          </Link>
      </li>
      <li>
        <Link to="/merchant/product">
          <Icon name="products" />
          <span>Product</span>
        </Link>
      </li>
      <li>
        <Link to="/merchant/order">
          <Icon name="orders" />
          <span>Order</span>
        </Link>
      </li>
    </NavList>
  );
};

export default LeftSidebar;

const NavList = styled.ul`
  ${({ theme }: { theme: any }) => `
  list-style-type: none;
  padding: 0;
  margin-left: 20px;

  & li {
    margin: 10px 0;
    & a {
      display: inline-flex;
      gap: 10px;
      align-items: center;
      text-decoration: none;
      color: white;
      padding: 5px 0;
    }
  }
  @media (max-width: 700px) {
    margin-left: 10px;
  }
  `}
`;
