import { FC, ReactNode } from "react";
import styled from "styled-components";
import LeftSidebar from "./leftSidebar";

interface ILayoutProps {
  children: ReactNode
}

const MerchantLayout: FC<ILayoutProps> = (props) => {
  const {children} = props;
  return (
    <>
      <Sidebar>
        <LeftSidebar />
      </Sidebar>
      <Content>{children}</Content>
    </>
  );
};

export default MerchantLayout;

const Sidebar = styled.div`
  ${({ theme }: { theme: any }) => `
    width: 180px;
    height: calc(100vh - 120px);
    background-color: lightcoral;
    float: left;

    @media (max-width: 700px) {
      width: 45px;      
      li a span {
        display: none;
      }
    }
  `}
`;

const Content = styled.div`
  ${({ theme }: { theme: any }) => `
    margin-left: 180px;
    height: calc(100vh - 120px);
    overflow-y: auto;

    @media (max-width: 700px) {
      margin-left: 45px;
    }
  `}
`;
