import { FC, ReactNode, useEffect, useRef } from "react";
import styled from "styled-components";
import { colors } from "src/lib/colors";

const ModalWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  &.show {
    display: flex;
  }
  &.right{
    justify-content: end;
    align-items: unset;
    height:100vh;
    overflow-y: hidden;

    & .modal-content {
      border-radius: 0;

      & .modal-body{
        height:100vh;
        overflow-y: auto;
        max-height:unset;
      }
    }
  }

  & .title{
    width: calc(100% - 15px);
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }

  & .close {
    color: ${colors?.textMuted};
    float: right;
    font-size: 28px;
    font-weight: bold;
    width: 15px;
  }

  & .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  & .modal-content {
    background-color: #fefefe;
    border: 1px solid #888;
    border-radius: 15px;
    max-width: 100%;
    &.small{
      width: 400px;
    }
    &.medium{
      width: 500px;
    }
    &.large{
      width: 600px;
    }
  }
  & .modal-header {
    display: flex;
    padding: 10px;
    border-bottom: 2px solid lightgrey;
  }
  & .modal-body {
    padding: 0 10px 20px;
    max-height: 70vh;
    overflow-y: auto;
  }

  & .modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }

  &.top{
    align-items: unset;
    width:100vw;
    overflow-x: hidden;
    max-height: 300px;
    top: 60px;

    & .modal-content {
      border-radius: 0;
      width: 100vw;

      // & .modal-body{
      //   width:100vw;
      //   overflow-y: auto;
      //   max-height:unset;
      // }
    }
  }
`;

interface IModalProps {
  open: boolean;
  children: ReactNode;
  onClose: () => void;
  header: string | null;
  size?: 'small' | 'medium' | 'large'
  position?: 'right' | 'top'
}

const Modal: FC<IModalProps> = (props) => {
  const { children, header, open, onClose, position, size='medium' } = props;

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && ref.current === event.target) {
        onClose();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref, onClose]);

  return (
    <ModalWrapper ref={ref} className={`${open ? "show " : ""} ${position??''}`}>
      <div className={`modal-content ${size}`}>
        {header !== null && <div className="modal-header">
          <div className="title">{header}</div>
          <div onClick={onClose} role="button" aria-label="Close" className="close">
            &times;
          </div>
        </div>}
        <div className="modal-body">{children}</div>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
