import { FC, useState } from 'react'
import Icon from 'src/icons/Icon';
import { FormControlWrapper, IconInputWrapper } from './textField.styled';

interface ITextFieldProps {
  id: string
  value: string
  onChange: (e: any) => void
  label: string
  name?: string
  helpText?: string
  error?: boolean
  fullWidth?: boolean
  errorMessage?: string
  icon?: string
  onClickIcon?: () => void
  onClickIconLabel?: string
}

const TextField: FC<ITextFieldProps> = (props) => {
  const {
    error, errorMessage, fullWidth, helpText, icon,
    id, label, name, onChange, onClickIcon, value, onClickIconLabel = "Click"
  } = props;
  const [isDirty, setIsDirty] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const handleFocus = () => {
    setIsFocus(true)
    setIsDirty(true)
  }
  const handleBlur = () => {
    setIsFocus(false)
  }

  const keyHandler = (e: any) => {
    if (e.keyCode === 13) {
      onClickIcon && onClickIcon()
    }
  }

  return icon ?
    <IconInputWrapper className={isFocus ? 'focus' : ''}>
      <input id={id} name={name} aria-label={`Enter the input for ${label}`} placeholder={label} onKeyDown={keyHandler} onFocus={handleFocus} onBlur={handleBlur} onChange={onChange} />
      <div onClick={onClickIcon} role="button" aria-label={onClickIconLabel} className='icon-container'>
        <Icon name={icon} />
      </div>
    </IconInputWrapper>
    : <FormControlWrapper>
      <input className={fullWidth ? 'w100' : ''} id={id} name={name} value={value} onFocus={handleFocus} onBlur={handleBlur} onChange={onChange} />
      <label htmlFor={id} className={value || isFocus ? 'active' : ''}>{label}</label>
      {error && errorMessage && <span className='error-text'>{errorMessage}</span>}
      {helpText && <span className='helper-text'>{helpText}</span>}
    </FormControlWrapper>
}

export default TextField;