import { FC } from 'react'
import ErrorBoundary from 'src/lib/errorBoundary';
import { useAppDispatch } from 'src/lib/redux/hooks';
import { addToCart } from 'src/lib/redux/slices/cartSlice';
import { getCurrencySymbol } from 'src/lib/utils';
import Button from 'src/components/basic/button/button';
import SlideShow from 'src/components/carousel/slideShow';
import { TwoColumnGrid } from 'src/components/styledComponents/base.styled';
import { Detail, Discount, PriceRow, Title } from './productDetail.styled';

interface IProductDetailProps {
  product: any
  isMerchant?: boolean
}

const ProductDetail: FC<IProductDetailProps> = (props) => {
  const { isMerchant = false, product } = props;
  const dispatch = useAppDispatch();

  const addProduct = () => {
    if (!isMerchant) {
      dispatch(
        addToCart({
          id: product.id,
          quantity: 1,
          name: product.name,
          thumbnail: product.imageURLs[0],
          price: product.price,
          currency: product.currency,
        })
      );
    }
  };
    return (
      <TwoColumnGrid>
        <div>
          <ErrorBoundary>
            {/* <ImageCarousel imageList={product.imageURLs} /> */}
            <SlideShow imageList={product?.imageURLs} />
          </ErrorBoundary>
        </div>
        <div>
          <Title>{product?.name}</Title>
          <PriceRow>
            <span>{getCurrencySymbol(product.currency)}</span>
            <span style={{marginRight: "10px"}}>{product?.price}</span>
            <Discount>{getCurrencySymbol(product.currency)}{product?.priceMax}</Discount>
          </PriceRow>
          <Detail>
            <p>{product?.name}</p>
          </Detail>
          <div>
            <Button label='Add To Cart' onClick={addProduct} />
          </div>
        </div>
      </TwoColumnGrid>
      );
}

export default ProductDetail;