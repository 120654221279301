import { useState } from "react";
import Dropdown from "src/components/basic/dropdown/dropdown";
import Button, { ButtonGroup } from "src/components/basic/button/button";
import Chip from "src/components/basic/chip/chip";
import Modal from "src/components/modal/modal";
import TextField from "src/components/basic/textField/textField";
import Icon from "src/icons/Icon";

const Test = () => {
  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState('');
  return (
    <>
      <h3 style={{ textAlign: "center" }}>Test Page</h3>

      <Chip />
      <hr/>
      <h4 style={{ textAlign: "center" }}>Text Field</h4>
      <TextField icon="search" id="sample" label="sample" fullWidth value={formValue} onChange={(e)=>{setFormValue(e.target.value)}} />
      <TextField id="sample1" label="sample" value={formValue} onChange={(e)=>{setFormValue(e.target.value)}} />
      <hr/>
      <ButtonGroup orientation="horizontal">
        <Button label="Submit" onClick={() => {}} />
        <Button label="Cancel" onClick={() => {}} />
      </ButtonGroup>
      <Button type="icon" label={<Icon name="cart" />} onClick={() => {}} />
      <hr/>
      <Button label="Open Modal" onClick={() => setShow(true)} />
      <Modal
        size="large"
        header="Sample Header"
        open={show}
        onClose={() => setShow(false)}
      >
        <div>Some sample content</div>
      </Modal>
      <hr/>
      <h3 style={{ textAlign: "center" }}>Dropdown</h3>
<Dropdown label="label" value="a" options={["a", 'b']} onChange={()=>{}} />
      <hr/>
      <h3 style={{ textAlign: "center" }}>Icons</h3>
      {icons.map(icon => <Icon key={icon} name={icon} tooltip={icon} />)}
    </>
  );
};

const icons: Array<string> = ['logo', 'cart', 'horizontalBars', 'search', 'plus', 'facebook', 'instagram', 'linkedin', 'twitter', 'edit', 'delete', 'dashboard', 'orders', 'products']

export default Test;
