import { FC, ReactNode } from 'react'
import { TableWrapper } from './table.styled';

interface ITableProps {
  children: ReactNode
  type?: 'table-striped'
}

const Table: FC<ITableProps> = (props) => {
  const {children, type = ''} = props;
  return <TableWrapper className={type}>{children}</TableWrapper>
}

export default Table;