import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PageState = {
  orderList: Array<any>;
  orderCount: number | null;
};

const initialState: PageState = {
  orderList: [],
  orderCount: null,
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateOrderList: (state, action: PayloadAction<any>) => {
      state.orderList = action.payload
    },
    updateOrderCount: (state, action: PayloadAction<any>) => {
      state.orderCount = action.payload
    },
    resetOrderSlice: (state) => (initialState),
  },
  extraReducers: (builder) => {

  },
});

export const { updateOrderList, updateOrderCount, resetOrderSlice } = orderSlice.actions;

export default orderSlice.reducer;
