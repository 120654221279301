import styled from "styled-components";
import { colors } from "src/lib/colors";

export const CarouselWrapper = styled.div`
  height: 300px;
  position: relative;
  // margin: auto;
`;

export const Slide = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  animation-name: fade;
  animation-duration: 1.5s;

  &.active {
    display: flex;
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  img {
    object-fit: contain;
    max-height: 250px;
    max-width: 200px;
  }
`;

export const CarouselControl = styled.div`
  button {
    outline: none;
    border: none;
    background-color: ${colors?.textMuted};
  }
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    // padding: 10px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 50%;
    user-select: none;
  }
  .next {
    right: 0;
    // border-radius: 3px 0 0 3px;
  }
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

export const DotNavigator = styled.div`
  text-align: center;
  & .dot {
    cursor: pointer;
    height: 8px;
    width: 8px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  & .active,
  .dot:hover {
    background-color: #717171;
  }
`;
