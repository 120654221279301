import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { calculateAmount } from "../../utils";

export type PageState = {
  productList: Array<any>;
  searchString: string;
  productCount: number | null;
  productDetail: Record<string, any>
};

const initialState: PageState = {
  productList: [],
  searchString: '',
  productCount: null,
  productDetail: {}
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateSearchString: (state, action: PayloadAction<any>) => {
      state.searchString = action.payload
    },
    updateProductList: (state, action: PayloadAction<any>) => {
      state.productList = action.payload
    },
    updateProductCount: (state, action: PayloadAction<any>) => {
      state.productCount = action.payload
    },
    updateProductDetail: (state, action: PayloadAction<any>) => {
      state.productDetail = { ...state.productDetail, [action.payload.productId]: action.payload.productDetail }
    },
    resetProductSlice: (state) => (initialState),
  },
  extraReducers: (builder) => {

  },
});

export const { updateSearchString, updateProductList, updateProductCount, updateProductDetail, resetProductSlice } = productSlice.actions;

export default productSlice.reducer;
