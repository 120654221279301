import { FC } from 'react'
import { ChipWrapper } from './chip.styled';

interface IChipProps {
  type?: 'default' | 'primary' | 'secondary'
}

const Chip: FC<IChipProps> = (props) => {
  return <ChipWrapper>
    <span>Label</span>
  </ChipWrapper>
}

export default Chip;