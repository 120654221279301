import { FC } from 'react'
import { useAppSelector } from 'src/lib/redux/hooks';
import { calculateAmount } from 'src/lib/utils';
import Table from '../table/table';

interface IOrderSummaryProps {
  
}

const OrderSummary: FC<IOrderSummaryProps> = (props) => {
  const cartItems = useAppSelector((state) => state?.cart?.items)

    const getSubtotal = () =>
      calculateAmount(cartItems?.reduce((a:number, c:any) => a + c.subTotal, 0));
  
    return (
      <div>
        <h3>Your Order</h3>
        <Table type='table-striped'>
          <thead>
            <tr>
              <th>Product</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {cartItems?.map((item:any) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.subTotal}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>Subtotal</th>
              <td>{getSubtotal()}</td>
            </tr>
            <tr>
              <th>Shipping</th>
              <td>$0.00</td>
            </tr>
            <tr>
              <th>Total</th>
              <td>{getSubtotal()}</td>
            </tr>
          </tfoot>
        </Table>
      </div>
    )
}

export default OrderSummary;