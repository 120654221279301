import { FC, useEffect } from "react";
import { SnackBarWrapper } from "./snackbar.styled";

interface ISnackbarProps {
  label: string;
  show: boolean;
  onClose: () => void;
  timeout?: number;
  type?: 'info' | 'warning' | 'error';
}

const Snackbar: FC<ISnackbarProps> = (props) => {
  const { label, onClose, show, timeout = 3, type="info" } = props;

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        onClose();
      }, timeout * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <SnackBarWrapper className={show && label ? `show ${type}` : ""}>
      <p>{label}</p>
    </SnackBarWrapper>
  );
};

export default Snackbar;
