import { FC } from 'react'
import useMediaQuery from 'src/lib/hooks/useMediaQuery';
import { useAppSelector } from 'src/lib/redux/hooks';
import Dashboard from './dashboard';
import Delete from './delete';
import Edit from './edit';
import Facebook from './facebook';
import Himanshu from './himanshu';
import HorizontalBars from './horizontalBars';
import Instagram from './instagram';
import Linkedin from './linkedin';
import Orders from './orders';
import Plus from './plus';
import Products from './products';
import Search from './search';
import ShoppingCart from './shoppingCart';
import Twitter from './twitter';

interface IIconProps {
  name: string
  size?: "xs" | "sm" | "md"
  color?: string
  tooltip?: string
}

const Icon: FC<IIconProps> = (props) => {
  const {name, tooltip, size="sm", color = null} = props;
  const isSmallScreen = useMediaQuery('(max-width: 500px)')
  const iconColor = useAppSelector((state) => state?.main?.theme?.colors?.icon || "#000")
  const logoColor = useAppSelector((state) => state?.main?.theme?.colors?.logo || "#000")

  const defaultProps = {
    fillColor: color ?? iconColor,
    size: getIconSize(size),
    title: tooltip
  }
  
  switch (name) {
    case "logo":
      return <Himanshu size={30} fillColor={logoColor} isSmallScreen={isSmallScreen} />
    case "cart":
      return <ShoppingCart {...defaultProps} />
    case "dashboard":
      return <Dashboard {...defaultProps} />
    case "delete":
      return <Delete {...defaultProps} />
    case "edit":
      return <Edit {...defaultProps} />
    case "facebook":
      return <Facebook {...defaultProps} />
    case "horizontalBars":
      return <HorizontalBars {...defaultProps} />
    case "instagram":
      return <Instagram {...defaultProps} />
    case "linkedin":
      return <Linkedin {...defaultProps} />
    case "orders":
      return <Orders {...defaultProps} />
    case "plus":
      return <Plus {...defaultProps} />
    case "products":
      return <Products {...defaultProps} />
    case "search":
      return <Search {...defaultProps} />
    case "twitter":
      return <Twitter {...defaultProps} />
  
    default:
      return <></>
  }
}

const getIconSize = (size: string) =>{
  switch (size) {
    case 'xs':
      return 16;
    case 'sm':
      return 25;
    case 'md':
      return 40;
    default:
      return 25;
  }
}

export default Icon;