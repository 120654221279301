import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PageState = {
    theme: any;
  loaderCount: number;
  isLoggedin: boolean;
  userType: USER_TYPE | null;
};
export enum USER_TYPE {
    CUSTOMER = 'CUSTOMER',
    ADMIN = 'ADMIN',
    MERCHANT = 'MERCHANT'
  }

const initialState: PageState = {
    theme: null,
  loaderCount: 0,
  isLoggedin: false,
  userType: null
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addLoader: (state) => {
      state.loaderCount = state.loaderCount + 1;
    },
    removeLoader: (state) => {
      state.loaderCount = state.loaderCount > 0 ? state.loaderCount - 1 : 0;
    },
    clearLoader: (state) => {
      state.loaderCount = 0;
    },
    updateTheme: (state, action: PayloadAction<any>) => {
      state.theme = action.payload
    },
    updateUserType: (state, action: PayloadAction<any>) => {
        state.userType = action.payload
      },
    resetMainSlice: (state) => ({...initialState, theme: state.theme}),
  },
  extraReducers: (builder) => {

  },
});

export const { addLoader, removeLoader, clearLoader, updateTheme, resetMainSlice, updateUserType } = mainSlice.actions;

export default mainSlice.reducer;
