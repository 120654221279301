import { FC, useEffect, useState } from "react";
import { useAppSelector } from "src/lib/redux/hooks";
import { PaginationWrapper } from "./pagination.styled";

interface IPaginationProps {
  currentPage: number;
  totalItem: number | null;
  itemPerPage?: number;
  onChange: (pageNumber: number) => void;
}

const Pagination: FC<IPaginationProps> = (props) => {
  const { currentPage, totalItem, onChange, itemPerPage = 10 } = props;

  
  const theme = useAppSelector((state) => state?.main?.theme)
  
  const [pnList, setPnList] = useState<Array<number>>([]);
  const [lastPage, setLastPage] = useState<number>(1);
  
  useEffect(() => {
    if(totalItem !== null){
      const x = Math.ceil(totalItem / itemPerPage);
      setLastPage(x)
      let from = currentPage - 2 > 1 ? currentPage - 2 : 1;
      let to = from + 4;
      if (to > x) {
        to = x;
        from = to - 4 > 1 ? to - 4 : 1;
      }
      const y = Array(to - from + 1)
        .fill(null)
        .map((_, i) => from + i);
      setPnList(y);
    }
  }, [totalItem, itemPerPage, currentPage]);

  return totalItem === null || totalItem <= itemPerPage ? <></> : (
    <PaginationWrapper theme={theme}>
      <li>
        <button disabled={currentPage<=1} onClick={() => {onChange(currentPage-1)}}>Previous</button>
      </li>
      {pnList.map((i) => (
        <li className={currentPage === i ? "active" : ""} key={i}>
          <button disabled={currentPage === i} onClick={() => {onChange(i)}}>{i}</button>
        </li>
      ))}
      <li>
        <button disabled={currentPage===lastPage} onClick={() => {onChange(currentPage+1)}}>Next</button>
      </li>
    </PaginationWrapper>
  );
};

export default Pagination;
