import styled from "styled-components";

export const ChipWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    // background-color: #F4632E;
    background-color: #1976D2;
    color: #FFF;
    height: 32px;
    // min-width: 100px;
    max-width: 100%;
    font-size: 14px;
    border-radius: 16px;
    white-space: nowrap;
    cursor: default;
    outline: 0;
    text-decoration: none;
    border: 0;
    padding: 0;
    vertical-align: middle;
    box-sizing: border-box;
    box-shadow: 0px 1px 7px 0px rgb(0 0 0 / 18%);
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    & span{
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 16px;
        white-space: nowrap;
    }
`