import { FC } from 'react'
import CheckoutSteps from 'src/components/checkoutSteps/checkoutSteps';
import OrderSummary from 'src/components/orderSummary/orderSummary';
import { TwoColumnGrid } from 'src/components/styledComponents/base.styled';


const Checkout: FC = () => {
  return (
    <TwoColumnGrid>
      <div>
        <OrderSummary />
      </div>
      <div>
        <CheckoutSteps />
      </div>
    </TwoColumnGrid>
  )
}

export default Checkout;