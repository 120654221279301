import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/lib/redux/hooks";
import { addToCart } from "src/lib/redux/slices/cartSlice";
import { calculateAmount, getCurrencySymbol } from "src/lib/utils";
import Button, { ButtonGroup } from "src/components/basic/button/button";
import Modal from "src/components/modal/modal";
import {
  CartItem,
  ItemCount,
  ItemCounter,
  ItemCountWrapper,
  Iteminfo,
  ItemPrice,
  ItemTotal,
  Thumbnail,
  Title,
  UpdateButton,
} from "./cart.styled";

interface ICartProps {
  open: boolean;
  onClose: () => void;
}

const Cart: FC<ICartProps> = (props) => {
  const { onClose, open } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const cartItems = useAppSelector((state) => state?.cart?.items)

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    if (open) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "unset";
    }
  }, [open]);

  const updateItem = (item: any, quantity: any) => {
    dispatch(addToCart({ ...item, quantity }));
  };

  const getTotal = () =>
    calculateAmount(
      cartItems?.reduce((a: number, c: any) => a + c.subTotal, 0)
    );

    const handleCheckout = () => {
      onClose();
      navigate("/checkout")
    }

  return (
    <Modal
      size="small"
      position="right"
      header="Shopping Cart"
      open={open}
      onClose={onClose}
    >
      <ul style={{ padding: "0", marginBlockStart: "0" }}>
        {cartItems?.map((item: any) => (
          <CartItem key={item.id}>
            <Thumbnail>
              <img src={item.thumbnail} alt="" loading="lazy" decoding="async" />
            </Thumbnail>
            <Iteminfo>
              <Title>{item.name}</Title>
              <ItemPrice>
                Unit Price {getCurrencySymbol(item.currency)} {item.price}
              </ItemPrice>
              <ItemCountWrapper>
                <ItemCounter>
                  <UpdateButton
                    onClick={() => updateItem(item, -1)}
                    className="decrement"
                  >
                    -
                  </UpdateButton>
                  <ItemCount>{item.quantity}</ItemCount>
                  <UpdateButton
                    onClick={() => updateItem(item, 1)}
                    className="increment"
                  >
                    +
                  </UpdateButton>
                </ItemCounter>
                <ItemTotal>
                  {getCurrencySymbol(item.currency)} {item.subTotal}
                </ItemTotal>
              </ItemCountWrapper>
            </Iteminfo>
          </CartItem>
        ))}
      </ul>
      <ButtonGroup>
        <Button
          label={`Proceed to Checkout | ${getTotal()}`}
          onClick={handleCheckout}
          disabled={cartItems?.length === 0}
        />
      </ButtonGroup>
    </Modal>
  );
};

export default Cart;
