import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductDetail from "src/components/productDetail/productDetail";
import axios from "src/lib/axios";

const ProductDetailPage = () => {
  const { productId } = useParams();
  const [productDetail, setProductDetail] = useState({});
  useEffect(() => {
    axios
      .get(`/product/${productId}`)
      .then((data) => {
        setProductDetail(data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [productId]);
  return <>
  <ProductDetail product={productDetail} />
  </>
};

export default ProductDetailPage;
