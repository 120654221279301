import { currencySymbols } from "./constants";

export const camelToTitle = (camelCase: string): string => {
  return camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .trim()
    .replace(/^./, (match) => match.toUpperCase());
};

export const typeOfData = (data: any) => {
  let temp;
  try {
    if (typeof data === "string") {
      temp = JSON.parse(data);
    } else {
      temp = data;
    }

    if (typeof temp === "object") {
      if (Array.isArray(temp)) {
        return "list";
      } else if (temp === null) {
        return "null";
      }
    }
    return typeof temp;
  } catch (e) {
    return typeof data;
  }
};

export const calculateAmount = (amount: string | number) => {
  try {
    return Number(Number(amount).toFixed(2));
  } catch (e) {
    console.log("calculateAmount error:", e);
  }
};

export const formatMoney = (amount: string | number) => {
  try {
    return String(amount).replace(/\d(?=(\d{3})+\.)/g, "$&,"); // to add comma
  } catch (e) {
    console.log("formatMoney error:", e);
  }
};

export const getCurrencySymbol = (currencyCode: string) => {
  return currencySymbols[currencyCode]
};

export const randomNumberString = Math.random().toString().substring(2);

export const hexToRGB = (hex: string, alpha?: number) => {
  if(!hex) return ''
  const r = parseInt(hex.slice(1,3), 16)
  const g = parseInt(hex.slice(3,5), 16)
  const b = parseInt(hex.slice(5,7), 16)
  if(alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  } else {
    return `rgb(${r}, ${g}, ${b})`

  }
}


export const getCurrentOS = () => {
  let userAgent = ''
  let platform;
  let os;

  if(typeof window !== 'undefined'){
    userAgent = window.navigator.userAgent
    platform = (window as any).navigator?.userAgentData?.platform || window.navigator.platform
  }
  const macPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macos']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']

  if(macPlatforms.includes(platform)) {
    os = "Mac"
  } else if (iosPlatforms.includes(platform)){
    os = "IOS"
  } else if (windowsPlatforms.includes(platform)){
    os = "Windows"
  } else if(/Android/.test(userAgent)) {
    os = "Android"
  } else if(/Linux/.test(platform)) {
    os = "Linux"
  }
  return os
}
