import styled from "styled-components";

export const NavbarWrapper = styled.nav`
  ${({ theme }: { theme: any}) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    background-color: ${theme?.colors?.navbar};
    height: 62px;

    @media (max-width: 500px) {
      gap: 10px;
    }
  `}
`;


export const NavItemWrapper = styled.ul`
${({ isSmallScreen }: { isSmallScreen: boolean}) => `
  list-style-type: none;
  margin: 0;
  padding: 0px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: ${isSmallScreen ? 'column' : 'row'};
  & li {
    float: right;
    & a {
      display: block;
      text-decoration: none;
    }
  }
  `}
`